.trend__card .question {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.8px;
  color: #010b1d;
  opacity: 1;
}

.trend__card .posted {
  font-size: 12px;
  letter-spacing: 0.8px;
  opacity: 0.87;
  color: #bfbfbf;
}

.trend__card .poster {
  color: #286cff;
}