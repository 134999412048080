.faq .page__header {
  text-align: left;
  font-size: 32px;
  letter-spacing: 2.5px;
  color: #010b1d;
  opacity: 1;
  text-align: center;
}

.faq .page__content {
  font-size: 14px;
  letter-spacing: 1.1px;
  color: #010b1d;
  opacity: 0.67;
  text-align: center;
}

.faq .grey-bg {
  background-color: #eff0f2;
}