.about .page__header {
  text-align: left;
  font-size: 32px;
  letter-spacing: 2.5px;
  color: #010b1d;
  opacity: 1;
  text-align: center;
}

.about .page__content {
  font-size: 14px;
  text-align: center;
}

.about .about__name {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.1px;
  color: #010b1d;
  opacity: 0.87;
}

.about .about__post {
  font-size: 12px;
  letter-spacing: 1.1px;
  color: #010b1d;
  opacity: 0.87;
}