.comment__card .comment {
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.95px;
  color: #010b1d;
  opacity: 1;
  opacity: 0.87;
  line-height: 1.75em;
}

.comment__card .posted {
  font-size: 12px;
  letter-spacing: 0.8px;
  opacity: 0.87;
  color: #bfbfbf;
}

.comment__card .poster {
  color: #286cff;
}