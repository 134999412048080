  .wrapper {
    background-color: #fff;
  }

  .mini__header {
    color: #ffaa00;
    opacity: 0.87;
  }

  .header {

    color: #010b1d;
    opacity: 1;
    max-width: 500px;
  }

  .content {
    color: #010b1d;
    opacity: 0.87;
    max-width: 500px;
  }

  .subscription .card__header {
    color: #009c2a;
    opacity: 0.87;
    text-align: left;
  }

  .subscription .card__price {
    text-align: left;
  }


  .subscription .list {
    opacity: 0.87;
    text-align: left;
  }

  .discussion__title {

    color: #010b1d;
    opacity: 1;
    max-width: 400px;
  }

  .green_bg {
    background-color: #009c2a;
  }

  .discussion__card__title {
    letter-spacing: 1.1px;
    color: #ffffff;
    opacity: 0.87;
  }

  .discussion__card__content {
    letter-spacing: 1.1px;
    color: #ffffff;
    opacity: 0.87;
  }

  .move__top {
    margin-top: -18rem;
  }