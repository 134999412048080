  .forum__header {
    text-align: center;
    font-size: 32px;
    letter-spacing: 2.5px;
    color: #010b1d;
    opacity: 1;
    max-width: 600px;
    margin: auto;
  }

  .forum__subheader {
    text-align: center;

    font-size: 15px;
    letter-spacing: 1.4px;
    color: #010b1d;
    max-width: 600px;
    opacity: 0.77;
    margin: auto;
  }

  .form__border {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 1px solid #0b054733;
    border-radius: 3px;
    opacity: 1;
    padding: 15px;
    margin: auto;
  }

  input,
  select {
    text-align: left;
    font-size: 11px;
    font-weight: semibold;

    letter-spacing: 0.75px;
    color: #0b0547;
    opacity: 1;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  .trending {
    text-align: left;

    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.95px;
    color: #010b1d;
    opacity: 1;
  }

  .active__tab {
    color: #286cff;
    border-bottom: 2px solid #286cff;
  }

  .inactive__tab {
    border-bottom: 2px solid #70707024;
  }

  .pagination {
    text-align: left;

    letter-spacing: -0.08px;
    color: #718196;
    opacity: 1;
  }

  .modal__header {
    text-align: center;
    font-size: 22px;
    letter-spacing: 2.5px;
    color: #010b1d;
    opacity: 1;
    max-width: 600px;
    margin: auto;
  }

  .modal__subtitle {
    text-align: center;

    font-size: 15px;
    letter-spacing: 1.4px;
    color: #010b1d;
    max-width: 600px;
    opacity: 0.77;
    margin: auto;
  }

  .top__part {
    background-color: #0301111a;
  }

  .modal__form__border {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 1px solid #6d76838f;
    border-radius: 3px;
    opacity: 1;
    padding: 10px;
    margin: auto;
  }

  .modal__label {

    font-size: 14px;
    letter-spacing: 0.48px;
    color: #5c6574;
    opacity: 1;
  }