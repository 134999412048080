.dashboard__page .name {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 157.5%;
  letter-spacing: 0.06em;
  color: #000;
}

.dashboard__page .others {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 157.5%;
  letter-spacing: 0.06em;
  color: #000;
}

.dashboard__page .top__wrapper {
  background: #f7fafe;
}

.dashboard__page .card__title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}

.dashboard__page .card__lessons {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}

.dashboard__page .card__duration {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-transform: capitalize;
  color: #fff;
}

.dashboard__page .top__text {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.06em;
  color: rgba(0, 0, 0, 0.65);
}