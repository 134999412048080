.footer__right__content {
  font: normal normal normal 14px Poppins;
  letter-spacing: 1.1px;
  color: #fff;
  opacity: 0.87;
}


/* .wrapper {
  background-color: #286cff;
} */

.footer__title {
  letter-spacing: 2.5px;
  color: #fff;
  opacity: 1;
  max-width: 400px;
}