.popular {
  background-color: #fff1f8;
}

.topics__header {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.1px;
  color: #010b1d;
  opacity: 0.97;
}

.card {
  background-color: #021e40;
  height: 300px;
  width: 200px;
  display: inline-block;
}

.card__title {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.1px;
  color: #ffffff;
  opacity: 0.87;
}

.card__wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}