.course__sidemenu .title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #000;
}

.course__sidemenu .assessment {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #000;
}