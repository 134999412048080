.chat__bubble .bubble {
  letter-spacing: 0px;
  color: #707070;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 20px 0px;
  opacity: 1;
}

.chat__bubble .desc {
  font-size: 12px;
  letter-spacing: 0.8px;
  opacity: 0.87;
  color: #bfbfbf;
}

.chat__bubble .poster {
  color: #286cff;
}

.chat__bubble .my__chat {
  float: right;
  background-color: red;
}