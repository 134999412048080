.quiz__wrapper .counter {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #000;
  text-transform: uppercase;
}

.quiz__wrapper .question {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #000;
}

.quiz__wrapper .option {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.065em;
  color: #000;
}

.quiz__wrapper .assesment__description {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.105em;
  color: #000;
  display: block;
  margin: 20px auto;
}