.thread__card .question__title {
  font-size: 15px;
  font-weight: semi-bold;
  letter-spacing: 0.95px;
  color: #010b1d;
  opacity: 1;
}

.thread__card .posted {
  font-size: 12px;
  letter-spacing: 0.8px;
  opacity: 0.87;
  color: #bfbfbf;
}

.thread__card .question__body {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0.95px;
  color: #010b1d;
  opacity: 0.77;
}

.thread__card .poster {
  color: #286cff;
}