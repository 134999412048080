.preview__card .name {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: #010b1d;
  opacity: 1;
}

.preview__card .desc {
  font-size: 12px;
  letter-spacing: 0.8px;
  opacity: 0.87;
  color: #bfbfbf;
}

.preview__card .poster {
  color: #286cff;
}