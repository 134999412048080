.wrapper {
  min-height: 85vh;
}

.main__text {
  text-align: center;
  font-size: 48px;
  padding: 20px auto;

  letter-spacing: 2.5px;
  color: #ffffff;
  opacity: 1;
  max-width: 900px;
  margin: 100px auto 10px;
}

.mini__text {
  text-align: center;

  font-weight: 400px;
  font-size: 14px;
  letter-spacing: 1.3px;
  color: #ffffff;
  opacity: 0.87;
  max-width: 800px;
  margin: auto;
}

.card__title {
  font-size: 18px;
  font-weight: bold;

  letter-spacing: 1.1px;
  color: #010b1d;
  opacity: 0.87;
}

.card__body {
  font-size: 14px;

  letter-spacing: 1.1px;
  color: #010b1dab;
  opacity: 1;
}

.boxed__container .why-the-best {
  margin-top: -100px !important;
}

@media screen and (max-width: 990px) {
  .boxed__container .why-the-best {
    margin-top: 10px !important;
    margin: 0
  }
}

.boxed__container .feature_icon {
  margin-top: -40px !important;
}