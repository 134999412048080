.auth__header .text {
  color: #0b0547;
  opacity: 1;
}

.auth__header .nav__button {
  background: #e9f0ff 0% 0% no-repeat padding-box;
  border: 2px solid #286cff;
  border-radius: 5px;
  opacity: 1;
  padding: 10px 20px;
  color: #2870fd;
}

.quote {
  color: #fff;
  opacity: 0.89;
}

.quote__name {
  font-weight: bold;
  color: #fff;
  opacity: 1;
}