.container {
    padding: 0 140px;
}

@media (max-width: 990px) {
    .container {
        padding: 0 20px;
    }
}

.list {
    list-style-type: decimal;
    margin: 0 25px;
}