.discussion {
  background-color: #fff;
}

.title {
  letter-spacing: 2.5px;
  color: #010b1d;
  opacity: 1;
  max-width: 400px;
}

.green_bg {
  background-color: #009c2a;
}

.card__title {
  font: normal normal bold 14px Poppins;
  letter-spacing: 1.1px;
  color: #ffffff;
  opacity: 0.87;
}

.card__content {
  font: normal normal normal 14px Poppins;
  letter-spacing: 1.1px;
  color: #ffffff;
  opacity: 0.87;
}

.top__header {
  text-align: left;
  font-size: 18px;
  font-weight: bold;

  letter-spacing: 1.1px;
  color: #ffaa00;
  opacity: 0.87;
}

hr {
  border: 1.25px solid #ffaa00;
}

.right__content {
  font-size: 14px;

  letter-spacing: 1.1px;
  color: #010b1d;
  opacity: 0.87;
  max-width: 50px;
}

.yellow {
  background-color: #ffaa00;
}