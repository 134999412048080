.form__label {
  font-size: 13px;
  font-weight: semibold;
  margin: 7px 0;

  letter-spacing: 0.77px;
  opacity: 0.5;
}

input,
select {
  text-align: left;
  font-size: 13px;
  font-weight: semibold;

  letter-spacing: 0.75px;
  color: #0b0547;
  opacity: 1;
}

.form__border {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #0b054733;
  border-radius: 3px;
  opacity: 1;
  padding: 10px;
  outline: 0;
}

.page__header {

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 157.5%;
  letter-spacing: 0.06em;
  color: #000000;
}

.sub__header {

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 157.5%;
  letter-spacing: 0.06em;
  color: #000000;
}

.hint {

  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 157.5%;
  letter-spacing: 0.06em;
  color: rgba(0, 0, 0, 0.25);
}

.card__price {
  letter-spacing: 2.15px;
  text-align: left;
}

.user {
  font: normal normal normal 18px "Circular Std Black";
  letter-spacing: 1.05px;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 181%;
  letter-spacing: 0.01em;
  color: #000000;
}

.sub__card {
  &:hover {
    border: 1px solid #286cff;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    cursor: pointer;
    transform: scale(1.1);
  }
}


.view__page {
  .user__header {
    text-align: center;
    font-size: 32px;
    letter-spacing: 2.5px;
    color: #010b1d;
    opacity: 1;
    margin: auto;
  }

  .user__subheader {
    text-align: center;

    font-size: 15px;
    letter-spacing: 1.4px;
    color: #010b1d;
    opacity: 0.77;
    margin: auto;
  }

  .form__border {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 1px solid #0b054733;
    border-radius: 3px;
    opacity: 1;
    padding: 15px;
    margin: auto;
  }

  input,
  textarea {
    text-align: left;
    font-size: 11px;
    font-weight: semibold;

    letter-spacing: 0.75px;
    color: #0b0547;

    opacity: 1;
  }

  .trending {
    text-align: left;

    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.95px;
    color: #010b1d;
    opacity: 1;
  }

  .blue__text {
    color: #286cff;
  }

  .limit__width {
    max-width: 600px;
    margin: auto;
  }

  .mini {
    font-size: 14px;

    letter-spacing: 0.85px;
    opacity: 0.87;
  }
}

.subscription__details {
  .title {
    font-size: 14px;
    line-height: 157.5%;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.65);
    text-transform: uppercase;
  }

  .description {
    font-weight: 600;
    font-size: 20px;
    line-height: 157.5%;
    letter-spacing: 0.06em;
    color: #000000;
    text-transform: capitalize;
  }
}