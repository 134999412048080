.post_page .post__header {
  text-align: left;
  font-size: 32px;
  letter-spacing: 2.5px;
  color: #010b1d;
  opacity: 1;
  margin: auto;
}

.post_page .post__subheader {
  text-align: left;
  font-size: 15px;
  letter-spacing: 1.4px;
  color: #010b1d;
  opacity: 0.77;
  margin: auto;
}

.post_page .form__border {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #0b0547 33;
  border-radius: 3px;
  opacity: 1;
  padding: 15px;
  margin: auto;
}

.post_page input,
.post_page textarea {
  text-align: left;
  font-size: 11px;
  font-weight: semibold;
  letter-spacing: 0.75px;
  color: #0b0547;
  opacity: 1;
}

.post_page .trending {
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.95px;
  color: #010b1d;
  opacity: 1;
}

.post_page .blue__text {
  color: #286cff;
}

.post_page .limit__width {
  max-width: 600px;
  margin: auto;
}

.post_page .posted {
  font-size: 12px;
  letter-spacing: 0.8px;
  opacity: 0.87;
  color: #bfbfbf;
}